// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import ujs from "@rails/ujs"
import * as activestorage from "@rails/activestorage"
import "popper.js/dist/umd/popper"
import "bootstrap/dist/js/bootstrap"
import "cocoon-rails"
import "../stylesheets/application.scss"

ujs.start()
activestorage.start()

require.context("../images", true)
